import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IProvisioning } from 'src/app/core/models/provisioning.model';

export const ProvisioningApiActions = createActionGroup({
  source: 'Provisioning API',
  events: {
    'Start Provisioning': props<{ tenantId: string }>(),
    'Get Access Status': props<{ tenantId: string }>(),
    'Get Access Status Success': props<{ status: boolean }>(),
    'Start Provisioning Success': props<{ provisioning: IProvisioning }>(),
    'Start Provisioning Failure': props<{ error: any }>(),
    'Empty Provisioning': emptyProps()
  }
});
