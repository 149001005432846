import { createReducer, on } from '@ngrx/store';
import { ISpace, Shift } from 'src/app/core/models/configuration.model';
import { ConfigurationApiActions } from '../../actions/configuration-api.actions';
import { ISelectItem } from '@ra-web-tech-ui-toolkit/form-system/select';

export const configurationApiFeatureKey = 'configurationApi';

export interface ConfigurationApiState {
  spaces: ISpace[];
  currentSpace: ISpace | undefined;
  shifts: Shift[];
  error: any;
  timeZones: ISelectItem[];
}

export const initialState: ConfigurationApiState = {
  spaces: [],
  currentSpace: undefined,
  shifts: [],
  error: {},
  timeZones: [],
};

export const reducer = createReducer(
  initialState,
  on(ConfigurationApiActions.loadSpaces, (state) => {
    return {
      ...state,
      spaces: [],
      currentSpace: {"space":[]},
    }
  }),
  on(ConfigurationApiActions.loadSpacesSuccess, (state, action) => {
    return {
      ...state,
      spaces: action.spaces,
      currentSpace: action.spaces[0] || undefined,
    }
  }),
  on(ConfigurationApiActions.loadSpacesFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    }
  }),
  on(ConfigurationApiActions.loadShifts, (state) => state),
  on(ConfigurationApiActions.loadShiftsSuccess, (state, action) => {
    return {
      ...state,
      shifts: action.shifts,
    }
  }),
  on(ConfigurationApiActions.loadShiftsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    }
  }),
  on(ConfigurationApiActions.setTimeZones, (state, action) => {
    return {
      ...state,
      timeZones: [...action.timeZones],
    }
  }),
);

