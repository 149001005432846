import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, of } from 'rxjs';
import { ProvisioningApiService } from 'src/app/core/services/provisioning-api/provisioning-api.service';
import { ProvisioningApiActions } from '../../actions/provisioning-api.actions';

@Injectable()
export class ProvisioningApiEffects {

  startProvisioning$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProvisioningApiActions.startProvisioning),
      exhaustMap(
        ({ tenantId }) => this.provisioningApi.startProvisioning(tenantId).pipe(
          map(
            provisioning => ProvisioningApiActions.startProvisioningSuccess({ provisioning })
          ),
          catchError(
            (error) => {
              return of(ProvisioningApiActions.startProvisioningFailure({ error }))
            }
          )
        )
      )
    )
  );
  getAccessStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProvisioningApiActions.getAccessStatus),
      switchMap(
        ({ tenantId }) => this.provisioningApi.getAccessStatus(tenantId).pipe(
          switchMap(status => {
            if (status.haveAccess) {
              return of(ProvisioningApiActions.getAccessStatusSuccess({ status : status.haveAccess }));
            } else {
              return of(
                ProvisioningApiActions.getAccessStatusSuccess({ status : status.haveAccess }),
                ProvisioningApiActions.getAccessStatus({ tenantId })
              );
            }
          }),
          catchError(
            (error) => {
              return of(ProvisioningApiActions.getAccessStatus({ tenantId }))
            }
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private provisioningApi: ProvisioningApiService
  ) { }
}
