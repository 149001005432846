import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AlarmGroupBy, IAlarm, IAlarmGroupByResponse, IAlarmKPIChart, IAlarmKPIItem, IAlarmListResponse, IAlarmRequest, IAlarmsByEventType, IAutomaticDiagnosticEventDetail } from 'src/app/core/models/alarm.model';

export const TotalAlarmCountApiActions = createActionGroup({
  source: 'Total Alarm Count API',
  events: {
    'Load Total Alarm Count': props<{ request: IAlarmRequest, groupBy: AlarmGroupBy.START_DATE | AlarmGroupBy.START_HOUR; }>(),
    'Load Total Alarm Class Count': props<{ request: IAlarmRequest, groupBy: AlarmGroupBy.ALARM_CLASS; }>(),
    'Load Total Alarm State Count': props<{ request: IAlarmRequest, groupBy: AlarmGroupBy.STATE; }>(),
    'Load Total Alarm Priority Count': props<{ request: IAlarmRequest, groupBy: AlarmGroupBy.PRIORITY; }>(),
    'Load Total Alarm Area Count': props<{ request: IAlarmRequest, groupBy: AlarmGroupBy.AREA; }>(),
    'Load Total Alarm Count Success': props<{ data: IAlarmGroupByResponse[], groupBy: AlarmGroupBy; }>(),
    'Load Total Alarm Count Failure': props<{ error: any; groupBy: AlarmGroupBy; }>(),
  }
});

export const AlarmApiActions = createActionGroup({
  source: 'Alarm API',
  events: {
    'Set Empty Alarms': emptyProps(),
    'Load Alarms': props<{ request: IAlarmRequest; }>(),
    'Load Alarms Success': props<{ data: IAlarmListResponse<IAlarm>; }>(),
    'Load Alarms Failure': props<{ error: any; }>(),
  }
});

export const TopAlarmApiActions = createActionGroup({
  source: 'Top Alarm API',
  events: {
    'Load Alarms By Duration': props<{ request: IAlarmRequest; }>(),
    'Load Alarms By Duration Success': props<{ data: IAlarmGroupByResponse[]; }>(),
    'Load Alarms By Duration Failure': props<{ error: any; }>(),
    'Load Alarms By Frequency': props<{ request: IAlarmRequest; }>(),
    'Load Alarms By Frequency Success': props<{ data: IAlarmGroupByResponse[]; }>(),
    'Load Alarms By Frequency Failure': props<{ error: any; }>(),
  }
});

export const AlarmsByEventTypeApiActions = createActionGroup({
  source: 'Alarms By Event Type API',
  events: {
    'Set Empty Alarms By Event Type': emptyProps(),
    'Load Alarms By Event Type': props<{ request: IAlarmRequest }>(),
    'Load Alarms By Event Type Success': props<{ data: IAlarmListResponse<IAlarmsByEventType>; }>(),
    'Load Alarms By Event Type Failure': props<{ error: any; }>(),
  }
});

export const AutomaticDiagnosticReportApiActions = createActionGroup({
  source: 'Automatic Diagnostic Report API',
  events: {
    'Set Empty Automatic Diagnostic Events': emptyProps(),
    'Load Automatic Diagnostic Events': props<{ request: IAlarmRequest; }>(),
    'Load Automatic Diagnostic Events Success': props<{ data: IAlarmListResponse<IAutomaticDiagnosticEventDetail>; }>(),
    'Load Automatic Diagnostic Events Failure': props<{ error: any; }>(),
  }
});

export const AlarmKPIChartsActions = createActionGroup({
  source: 'Alarm KPI Charts',
  events: {
    'Load Top Bad Actors': props<{ request: IAlarmRequest }>(),
    'Load Top Bad Actors Success': props<{ items: IAlarmKPIItem[] }>(),
    'Load Top Bad Actors Failure': props<{ error: any; }>(),
    'Load Fleeting Alarm Count': props<{ request: IAlarmRequest }>(),
    'Load Fleeting Alarm Count Success': props<{ data: IAlarmKPIChart, groupBy: AlarmGroupBy, timeLine: string }>(),
    'Load Fleeting Alarm Count Failure': props<{ error: any; }>(),
    'Load Chattering Alarm Count': props<{ request: IAlarmRequest }>(),
    'Load Chattering Alarm Count Success': props<{ data: IAlarmKPIChart, groupBy: AlarmGroupBy, timeLine: string }>(),
    'Load Chattering Alarm Count Failure': props<{ error: any; }>(),
    'Load Stale Alarm Count': props<{ request: IAlarmRequest }>(),
    'Load Stale Alarm Count Success': props<{ data: IAlarmKPIChart, groupBy: AlarmGroupBy, timeLine: string }>(),
    'Load Stale Alarm Count Failure': props<{ error: any; }>(),
    'Load Flooding Alarm Percentage': props<{ request: IAlarmRequest }>(),
    'Load Flooding Alarm Percentage Success': props<{ data: IAlarmKPIChart, groupBy: AlarmGroupBy, timeLine: string }>(),
    'Load Flooding Alarm Percentage Failure': props<{ error: any; }>(),
    'Load Average Alarm Rate': props<{ request: IAlarmRequest }>(),
    'Load Average Alarm Rate Success': props<{ data: IAlarmKPIChart, groupBy: AlarmGroupBy, timeLine: string }>(),
    'Load Average Alarm Rate Failure': props<{ error: any; }>(),
    'Load Previous Average Alarm Rate': props<{ request: IAlarmRequest, }>(),
    'Load Previous Average Alarm Rate Success': props<{ data: IAlarmKPIChart, groupBy: AlarmGroupBy, timeLine: string }>(),
    'Load Previous Average Alarm Rate Failure': props<{ error: any; }>(),
  }
});