import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@rockwell-automation-inc/service';
import { CallbackComponent } from './pages/callback/callback.component';
import { LogoutCallbackComponent } from './pages/logout-callback/logout-callback.component';
const routes: Routes = [
  {
    path: '',
    component: CallbackComponent,
  },
  {
    path: 'sign-in',
    component: CallbackComponent,
  },
  {
    path: 'callback',
    component: CallbackComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'logout',
    component: LogoutCallbackComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard-page.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard/alarms/overview'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/dashboard/alarms/overview'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
