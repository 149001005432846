import { createReducer, on } from '@ngrx/store';
import { IAlarmGroupedBy } from 'src/app/core/models/alarm.model';
import { TotalAlarmCountApiActions } from '../../actions/alarm-api.actions';
import { format } from 'date-fns';
export interface ITotalAlarmCountLoadingState {
  totalAlarmCount: boolean;
  totalAlarmClassCount: boolean;
  totalAlarmStateCount: boolean;
  totalAlarmPriorityCount: boolean;
  totalAlarmAreaCount: boolean;
}

export interface ITotalAlarmCountErrorState {
  totalAlarmCount: any;
  totalAlarmClassCount: any;
  totalAlarmStateCount: any;
  totalAlarmPriorityCount: any;
  totalAlarmAreaCount: any;
}

export interface ITotalAlarmCountState {
  charts: IAlarmGroupedBy;
  loading: ITotalAlarmCountLoadingState;
  errors: ITotalAlarmCountErrorState;
  lastUpdated?: string
};

export const initialState: ITotalAlarmCountState = {
  charts: {
    totalAlarmCount: {
      alarmArea: [],
      alarmClass: [],
      alarmGroup: [],
      alarmPriority: [],
      alarmState: [],
      totalCount: 0,
      formatDates:false
    },
    totalAlarmClassCount: [],
    totalAlarmStateCount: [],
    totalAlarmPriorityCount: [],
    totalAlarmAreaCount: [],
  },
  loading: {
    totalAlarmCount: true,
    totalAlarmClassCount: true,
    totalAlarmStateCount: true,
    totalAlarmPriorityCount: true,
    totalAlarmAreaCount: true,
  },
  lastUpdated: undefined,
  errors: {
    totalAlarmCount: undefined,
    totalAlarmClassCount: undefined,
    totalAlarmStateCount: undefined,
    totalAlarmPriorityCount: undefined,
    totalAlarmAreaCount: undefined,
  },
};

const alarmGroupByKeys: { [key: string]: string } = {
  startDate: 'totalAlarmCount',
  startHour: 'totalAlarmCount',
  alarmClass: 'totalAlarmClassCount',
  state: 'totalAlarmStateCount',
  priority: 'totalAlarmPriorityCount',
  area: 'totalAlarmAreaCount',
};

export const reducer = createReducer(
  initialState,
  on(TotalAlarmCountApiActions.loadTotalAlarmCount, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        totalAlarmCount: true,
      },
      errors: {
        ...state.errors,
        totalAlarmCount: undefined,
      },
    };
  }),
  on(TotalAlarmCountApiActions.loadTotalAlarmClassCount, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        totalAlarmClassCount: true,
      },
      errors: {
        ...state.errors,
        totalAlarmClassCount: undefined,
      },
    };
  }),
  on(TotalAlarmCountApiActions.loadTotalAlarmStateCount, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        totalAlarmStateCount: true,
      },
      errors: {
        ...state.errors,
        totalAlarmStateCount: undefined,
      },
    };
  }),
  on(TotalAlarmCountApiActions.loadTotalAlarmPriorityCount, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        totalAlarmPriorityCount: true,
      },
      errors: {
        ...state.errors,
        totalAlarmPriorityCount: undefined,
      },
    };
  }),
  on(TotalAlarmCountApiActions.loadTotalAlarmAreaCount, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        totalAlarmAreaCount: true,
      },
      errors: {
        ...state.errors,
        totalAlarmAreaCount: undefined,
      },
    };
  }),
  on(TotalAlarmCountApiActions.loadTotalAlarmCountSuccess, (state, action) => {
    const { groupBy, data } = action;
    const key = alarmGroupByKeys[groupBy];
    return {
      ...state,
      charts: {
        ...state.charts,
        [key]: key === 'totalAlarmCount' ? { ...data } : [...data],
      },
      loading: {
        ...state.loading,
        [key]: false,
      },
      lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
    };
  }),
  on(TotalAlarmCountApiActions.loadTotalAlarmCountFailure, (state, action) => {
    const { groupBy } = action;
    const key = alarmGroupByKeys[groupBy];
    return {
      ...state,
      errors: {
        ...state.errors,
        [key]: { ...action.error },
      },
      loading: {
        ...state.loading,
        [key]: false,
      },
    };
  })
);
