import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProvisioning } from '../../models/provisioning.model';

@Injectable({
  providedIn: 'root'
})
export class ProvisioningApiService {

  constructor(private http: HttpClient) { }

  getProvisioningStatus(tenantId: string): Observable<IProvisioning> {
    return this.http.get<IProvisioning>(`${environment.apiURL}/tenants/${tenantId}/provision`);
  }

  startProvisioning(tenantId: string): Observable<IProvisioning> {
    return this.http.post<IProvisioning>(`${environment.apiURL}/tenants/${tenantId}/provision`, {});
  }
  getAccessStatus(tenantId: string): Observable<{haveAccess : boolean}> {
    return this.http.get<{haveAccess : boolean}>(`${environment.apiURL}/tenants/${tenantId}/getaccessstatus`);
  }

}
