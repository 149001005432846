import { createReducer, on } from '@ngrx/store';
import {
  IAlarmListTableData,
  IAlarmsByEventType,
} from 'src/app/core/models/alarm.model';
import { AlarmsByEventTypeApiActions } from '../../actions/alarm-api.actions';
import { format } from 'date-fns';

export interface IAlarmsByEventTypeState {
  data: IAlarmListTableData<IAlarmsByEventType>;
  isLoading: boolean;
  error: any;
  lastUpdated?: string
}

export const initialState: IAlarmsByEventTypeState = {
  data: {
    items: [],
    nextCursor: '',
  },
  isLoading: true,
  lastUpdated: undefined,
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(AlarmsByEventTypeApiActions.setEmptyAlarmsByEventType, (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        items: null,
        nextCursor: '',
      },
      error: undefined,
    };
  }),
  on(AlarmsByEventTypeApiActions.loadAlarmsByEventType, (state) => {
    return {
      ...state,
      isLoading: true,
      error: undefined,
    };
  }),
  on(
    AlarmsByEventTypeApiActions.loadAlarmsByEventTypeSuccess,
    (state, action) => {
      return {
        ...state,
        data: {
          items: state.data.items === null ? [...action.data.items] : [...state.data.items, ...action.data.items],
          nextCursor: action.data.nextCursor,
        },
        isLoading: false,
        lastUpdated: format(new Date(), 'dd/LL/yyyy HH:mm:ss')
    };
    }
  ),
  on(
    AlarmsByEventTypeApiActions.loadAlarmsByEventTypeFailure,
    (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
  )
);
