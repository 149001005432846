import { createReducer, on } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { format } from 'date-fns';
import { AlarmState, ToggleDateFilter } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';
import { TopAlarmPageActions } from '../../actions/top-alarm-page.actions';

export interface TopAlarmPageState {
  dateFilter: string;
  toggleDateFilter: ToggleDateFilter | undefined;
  areaFilter: ValueType | undefined;
  groupFilter: ValueType | undefined;
  shiftFilter: Shift | null;
  durationStateFilter: {
    value: AlarmState;
    index: number;
  };
  frequencyStateFilter: {
    value: AlarmState;
    index: number;
  };
  durationStateView: {
    value: number;
    index: number;
  };
  frequencyStateView: {
    value: number;
    index: number;
  };
}

export const initialState: TopAlarmPageState = {
  dateFilter: format(new Date(), 'MMM / dd / yyyy'),
  toggleDateFilter: ToggleDateFilter.MONTH,
  areaFilter: 'all',
  groupFilter: 'all',
  shiftFilter: null,
  durationStateFilter: {
    value: AlarmState.ACTIVE,
    index: 0
  },
  frequencyStateFilter: {
    value: AlarmState.ACTIVE,
    index: 0,
  },
  durationStateView: {
    value: 10,
    index: 1,
  },
  frequencyStateView: {
    value: 10,
    index: 1,
  },
};

export const reducer = createReducer(
  initialState,
  on(TopAlarmPageActions.updateToggleDateFilter, (state, action) => {
    return {
      ...state,
      toggleDateFilter: action.toggleDateFilter
    }
  }),
  on(TopAlarmPageActions.setDateFilter, (state, action) => {
    return {
      ...state,
      dateFilter: action.dateFilter,
    }
  }),
  on(TopAlarmPageActions.setAreaFilter, (state, action) => {
    return {
      ...state,
      areaFilter: action.areaFilter,
    }
  }),
  on(TopAlarmPageActions.setGroupFilter, (state, action) => {
    return {
      ...state,
      groupFilter: action.groupFilter,
    }
  }),
  on(TopAlarmPageActions.setShiftFilter, (state, action) => {
    return {
      ...state,
      shiftFilter: action.shiftFilter,
    }
  }),
  on(TopAlarmPageActions.setDurationStateFilter, (state, action) => {
    const { value, index } = action;
    return {
      ...state,
      durationStateFilter: {
        value,
        index
      },
    }
  }),
  on(TopAlarmPageActions.setFrequencyStateFilter, (state, action) => {
    const { value, index } = action;
    return {
      ...state,
      frequencyStateFilter: {
        value,
        index
      },
    }
  }),
  on(TopAlarmPageActions.setDurationStateView, (state, action) => {
    const { value, index } = action;
    return {
      ...state,
      durationStateView: {
        value,
        index
      },
    }
  }),
  on(TopAlarmPageActions.setFrequencyStateView, (state, action) => {
    const { value, index } = action;
    return {
      ...state,
      frequencyStateView: {
        value,
        index
      },
    }
  }),
);

