import { createReducer, on } from '@ngrx/store';
import { IAlarmKPICharts } from 'src/app/core/models/alarm.model';
import { formatResponseMapperData } from 'src/app/shared/utils/general-utils';
import { AlarmKPIChartsActions } from '../../actions/alarm-api.actions';

export interface IAlarmKPIChartsLoading {
  topBadActors: boolean;
  fleetingAlarmCount: boolean;
  chatteringAlarmCount: boolean;
  staleAlarmCount: boolean;
  floodingAlarmPercentage: boolean;
  averageAlarmRate: boolean;
  previousAverageAlarmRate: boolean;
}

export interface IAlarmKPIChartsError {
  topBadActors: any;
  fleetingAlarmCount: any;
  chatteringAlarmCount: any;
  staleAlarmCount: any;
  floodingAlarmPercentage: any;
  averageAlarmRate: any;
  previousAverageAlarmRate: any;
}

export interface IAlarmKPIState {
  charts: IAlarmKPICharts;
  loading: IAlarmKPIChartsLoading;
  errors: IAlarmKPIChartsError;
}

export const initialState: IAlarmKPIState = {
  charts: {
    topBadActors: {
      tooltip: undefined,
      data: []
    },
    fleetingAlarmCount: undefined,
    chatteringAlarmCount: undefined,
    staleAlarmCount: undefined,
    floodingAlarmPercentage: undefined,
    averageAlarmRate: undefined,
    previousAverageAlarmRate: undefined,
  },
  loading: {
    topBadActors: false,
    fleetingAlarmCount: false,
    chatteringAlarmCount: false,
    staleAlarmCount: false,
    floodingAlarmPercentage: false,
    averageAlarmRate: false,
    previousAverageAlarmRate: false,
  },
  errors: {
    topBadActors: undefined,
    fleetingAlarmCount: undefined,
    chatteringAlarmCount: undefined,
    staleAlarmCount: undefined,
    floodingAlarmPercentage: undefined,
    averageAlarmRate: undefined,
    previousAverageAlarmRate: undefined,
  },
};

export const reducer = createReducer(
  initialState,
  // TOP BAD ACTORS
  on(AlarmKPIChartsActions.loadTopBadActors, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        topBadActors: [],
      },
      loading: {
        ...state.loading,
        topBadActors: true,
      },
      errors: {
        ...state.errors,
        topBadActors: undefined,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadTopBadActorsSuccess, (state, action) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        topBadActors: { ...action.items },
      },
      loading: {
        ...state.loading,
        topBadActors: false,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadTopBadActorsFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        topBadActors: false,
      },
      errors: {
        ...state.errors,
        topBadActors: action.error,
      },
    };
  }),
  // FLEETING ALARM COUNT
  on(AlarmKPIChartsActions.loadFleetingAlarmCount, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        fleetingAlarmCount: undefined,
      },
      loading: {
        ...state.loading,
        fleetingAlarmCount: true,
      },
      errors: {
        ...state.errors,
        fleetingAlarmCount: undefined,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadFleetingAlarmCountSuccess, (state, action) => {
    let formattedItems = [];
    if (action.timeLine === 'custom' && action.data.formatDates === false) {
      formattedItems = action.data.items;
    } else {
      formattedItems = formatResponseMapperData(
        action.groupBy,
        action.data.items
      );
    }
    return {
      ...state,
      charts: {
        ...state.charts,
        fleetingAlarmCount: {
          ...action.data,
          items: [...formattedItems],
        },
      },
      loading: {
        ...state.loading,
        fleetingAlarmCount: false,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadFleetingAlarmCountFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        fleetingAlarmCount: false,
      },
      errors: {
        ...state.errors,
        fleetingAlarmCount: action.error,
      },
    };
  }),
  // CHATTERING ALARM COUNT
  on(AlarmKPIChartsActions.loadChatteringAlarmCount, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        chatteringAlarmCount: undefined,
      },
      loading: {
        ...state.loading,
        chatteringAlarmCount: true,
      },
      errors: {
        ...state.errors,
        chatteringAlarmCount: undefined,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadChatteringAlarmCountSuccess, (state, action) => {
    let formattedItems = [];
    if (action.timeLine === 'custom' && action.data.formatDates === false) {
      formattedItems = action.data.items;
    } else {
      formattedItems = formatResponseMapperData(
        action.groupBy,
        action.data.items
      );
    }
    return {
      ...state,
      charts: {
        ...state.charts,
        chatteringAlarmCount: {
          ...action.data,
          items: [...formattedItems],
        },
      },
      loading: {
        ...state.loading,
        chatteringAlarmCount: false,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadChatteringAlarmCountFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        chatteringAlarmCount: false,
      },
      errors: {
        ...state.errors,
        chatteringAlarmCount: action.error,
      },
    };
  }),
  // STALE ALARM COUNT
  on(AlarmKPIChartsActions.loadStaleAlarmCount, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        staleAlarmCount: undefined,
      },
      loading: {
        ...state.loading,
        staleAlarmCount: true,
      },
      errors: {
        ...state.errors,
        staleAlarmCount: undefined,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadStaleAlarmCountSuccess, (state, action) => {
    let formattedItems = [];
    if (action.timeLine === 'custom' && action.data.formatDates === false) {
      formattedItems = action.data.items;
    } else {
      formattedItems = formatResponseMapperData(
        action.groupBy,
        action.data.items
      );
    }
    return {
      ...state,
      charts: {
        ...state.charts,
        staleAlarmCount: {
          ...action.data,
          items: [...formattedItems],
        },
      },
      loading: {
        ...state.loading,
        staleAlarmCount: false,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadStaleAlarmCountFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        staleAlarmCount: false,
      },
      errors: {
        ...state.errors,
        staleAlarmCount: action.error,
      },
    };
  }),
  // FLOODING ALARM PERCENTAGE
  on(AlarmKPIChartsActions.loadFloodingAlarmPercentage, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        floodingAlarmPercentage: undefined,
      },
      loading: {
        ...state.loading,
        floodingAlarmPercentage: true,
      },
      errors: {
        ...state.errors,
        floodingAlarmPercentage: undefined,
      },
    };
  }),
  on(
    AlarmKPIChartsActions.loadFloodingAlarmPercentageSuccess,
    (state, action) => {
      let formattedItems = [];
      if (action.timeLine === 'custom' && action.data.formatDates === false) {
        formattedItems = action.data.items;
      } else {
        formattedItems = formatResponseMapperData(
          action.groupBy,
          action.data.items
        );
      }
      return {
        ...state,
        charts: {
          ...state.charts,
          floodingAlarmPercentage: {
            ...action.data,
            items: [...formattedItems],
          },
        },
        loading: {
          ...state.loading,
          floodingAlarmPercentage: false,
        },
      };
    }
  ),
  on(
    AlarmKPIChartsActions.loadFloodingAlarmPercentageFailure,
    (state, action) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          floodingAlarmPercentage: false,
        },
        errors: {
          ...state.errors,
          floodingAlarmPercentage: action.error,
        },
      };
    }
  ),
  // AVERAGE ALARM RATE
  on(AlarmKPIChartsActions.loadAverageAlarmRate, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        averageAlarmRate: undefined,
      },
      loading: {
        ...state.loading,
        averageAlarmRate: true,
      },
      errors: {
        ...state.errors,
        averageAlarmRate: undefined,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadAverageAlarmRateSuccess, (state, action) => {
    let formattedItems = [];
    if (action.timeLine === 'custom' && action.data.formatDates === false) {
      formattedItems = action.data.items;
    } else {
      formattedItems = formatResponseMapperData(
        action.groupBy,
        action.data.items
      );
    }
    return {
      ...state,
      charts: {
        ...state.charts,
        averageAlarmRate: {
          ...action.data,
          items: [...formattedItems],
        },
      },
      loading: {
        ...state.loading,
        averageAlarmRate: false,
      },
    };
  }),
  on(AlarmKPIChartsActions.loadAverageAlarmRateFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        averageAlarmRate: false,
      },
      errors: {
        ...state.errors,
        averageAlarmRate: action.error,
      },
    };
  }),
  // PREVIOUS AVERAGE ALARM RATE
  on(AlarmKPIChartsActions.loadPreviousAverageAlarmRate, (state) => {
    return {
      ...state,
      charts: {
        ...state.charts,
        previousAverageAlarmRate: undefined,
      },
      loading: {
        ...state.loading,
        previousAverageAlarmRate: true,
      },
      errors: {
        ...state.errors,
        previousAverageAlarmRate: undefined,
      },
    };
  }),
  on(
    AlarmKPIChartsActions.loadPreviousAverageAlarmRateSuccess,
    (state, action) => {
      return {
        ...state,
        charts: {
          ...state.charts,
          previousAverageAlarmRate: action.data,
        },
        loading: {
          ...state.loading,
          previousAverageAlarmRate: false,
        },
      };
    }
  ),
  on(
    AlarmKPIChartsActions.loadPreviousAverageAlarmRateFailure,
    (state, action) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          previousAverageAlarmRate: false,
        },
        errors: {
          ...state.errors,
          previousAverageAlarmRate: action.error,
        },
      };
    }
  )
);
