import { createReducer, on } from '@ngrx/store';
import {
IProcessObjectRow,IProcessObjectTableData
} from 'src/app/core/models/process-object-configuration.model';
import { SiteManagementApiActions } from '../../actions/site-management-api.actions';

export interface ConfigurationState {
  data: IProcessObjectTableData<IProcessObjectRow>;
  loading: {
    isLoading: boolean;
    isUploadInProgress: boolean;
    uploadPercentage: number;
  };
}

export const initialState: ConfigurationState = {
  data: {
    items: [],
    nextCursor: ""
  },
  loading: {
    isLoading: false,
    isUploadInProgress: false,
    uploadPercentage: 0
  }
};

export const reducer = createReducer(
  initialState,
  on(SiteManagementApiActions.loadApplicationInfo, (state) => {
    return {
      ...state
    };
  })
);
