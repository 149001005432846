import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap,switchMap, map, of } from 'rxjs';
import { ConfigurationApiService } from 'src/app/core/services/configuration-api/configuration-api.service';
import { ConfigurationApiActions } from '../../actions/configuration-api.actions';

@Injectable()
export class ConfigurationApiEffects {
  loadSpaces$ = createEffect(
    () => this.actions$.pipe(
      ofType(ConfigurationApiActions.loadSpaces),
      switchMap(
        ({ tenantId , dataSetId}) => this.configurationApi.getSpaces(tenantId, dataSetId).pipe(
          map(
            spaces => ConfigurationApiActions.loadSpacesSuccess({ spaces })
          ),
          catchError(
            (error) => of(ConfigurationApiActions.loadSpacesFailure({ error }))
          )
        )
      )
    )
  );

  loadShifts$ = createEffect(
    () => this.actions$.pipe(
      ofType(ConfigurationApiActions.loadShifts),
      exhaustMap(
        ({ tenantId }) => this.configurationApi.getShifts(tenantId).pipe(
          map(
            shifts => ConfigurationApiActions.loadShiftsSuccess({ shifts })
          ),
          catchError(
            (error) => of(ConfigurationApiActions.loadShiftsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private configurationApi: ConfigurationApiService,
  ) { }
}
