import { createActionGroup, props } from '@ngrx/store';
import { ISelectItem } from '@ra-web-tech-ui-toolkit/form-system/select';
import { ISpace, Shift } from 'src/app/core/models/configuration.model';

export const ConfigurationApiActions = createActionGroup({
  source: 'Configuration API',
  events: {
    'Load Spaces': props<{ tenantId: string , dataSetId: number }>(),
    'Load Spaces Success': props<{ spaces: ISpace[] }>(),
    'Load Spaces Failure': props<{ error: any }>(),
    'Set Current Site': props<{ site: ISpace }>(),
    'Load Shifts': props<{ tenantId: string }>(),
    'Load Shifts Success': props<{ shifts: Shift[] }>(),
    'Load Shifts Failure': props<{ error: any }>(),
    'Set Time Zones': props<{ timeZones: ISelectItem[] }>(),
  }
});
