import { createReducer, on } from '@ngrx/store';
import { ValueType } from '@ra-web-tech-ui-toolkit/common-utils/cdk';
import { ToggleDateFilterNew } from 'src/app/core/models/alarm.model';
import { Shift } from 'src/app/core/models/configuration.model';
import moment from 'moment';
import { AlarmOverviewPageActions } from '../../actions/alarm-overview-page.actions';
import { IDatePickerModel } from 'src/app/components/custom-date-picker/type';
import { ConfigurationApiActions } from '../../actions/configuration-api.actions';
export const alarmFeatureKey = 'alarmOverviewPage';

export interface AlarmOverviewPageState {
  dateFilter: IDatePickerModel;
  toggleDateFilter: ToggleDateFilterNew | undefined;
  areaFilter: ValueType | undefined;
  groupFilter: ValueType | undefined;
  shiftFilter: Shift | null;
  processObjFilter: ValueType | undefined;
}

export const initialState: AlarmOverviewPageState = {
  dateFilter:  {
    type: ToggleDateFilterNew.MONTH,
    date: moment().format('MMM DD, YYYY'),
    fromDate: moment().startOf('month').format('MMM DD, YYYY'),
    toDate: moment().format('MMM DD, YYYY')
  },
  toggleDateFilter: ToggleDateFilterNew.MONTH,
  areaFilter: 'all',
  groupFilter: 'all',
  processObjFilter: 'all',
  shiftFilter: null,
};

export const reducer = createReducer(
  initialState,
  on(AlarmOverviewPageActions.updateToggleDateFilter, (state, action) => {
    return {
      ...state,
      toggleDateFilter: action.toggleDateFilter
    }
  }),
  on(AlarmOverviewPageActions.setDateFilter, (state, action) => {
    return {
      ...state,
      dateFilter: action.dateFilter,
    }
  }),
  on(AlarmOverviewPageActions.setAreaFilter, (state, action) => {
    return {
      ...state,
      areaFilter: action.areaFilter,
    }
  }),
  on(AlarmOverviewPageActions.setGroupFilter, (state, action) => {
    return {
      ...state,
      groupFilter: action.groupFilter,
    }
  }),
  on(AlarmOverviewPageActions.setShiftFilter, (state, action) => {
    return {
      ...state,
      shiftFilter: action.shiftFilter,
    }
  }),
  on(AlarmOverviewPageActions.setProcessObjectFilter, (state, action) => {
    return {
      ...state,
      processObjFilter: action.processObjFilter,
    }
  }),
  on(ConfigurationApiActions.loadSpaces, (state, action) => {
    return {
      ...state,
      areaFilter: 'all',
      groupFilter: 'all'
    }
  }),
  on(AlarmOverviewPageActions.resetFilters, (state) => {
    return {
      ...state,
      areaFilter: 'all',
      groupFilter: 'all',
      processObjFilter: 'all',
      shiftFilter: null,
    };
  })
);

