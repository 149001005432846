// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.

import { CoreConfiguration } from '@rockwell-automation-inc/service';

export interface IAppConfiguration extends CoreConfiguration {
  serviceUrl: string;
  serviceKind: string;
}

const apiURL = 'https://apim-0394-plpx-centralus.azure-api.net/lfapp-node-bff-0394-plantpax-dev-01';

// The list of file replacements can be found in `angular.json`.
const appConfiguration: IAppConfiguration = {
  serviceUrl: 'http://127.0.0.1:7071/',
  serviceKind: 'ppaxanalytics',
  production: false,
  name: 'PlantPax Analytics',
  csApiBaseUrl: 'https://common.lemans-sandbox.rockwellautomation.com',
  csPortalUrl: 'https://dev.plantpaxanalytics.rockwellautomation.com',
  notificationsBaseUrl:
    'https://api-notifications.lemans-sandbox.rockwellautomation.com',
  authConfig: {
    domain: 'factorytalkhub-dev.us.auth0.com',
    clientId: '3XhF9f7ssQ0oXs5ytMPsrfMhXPOux0hL',
    audience: 'https://lemans.ppaxanalytics',
    redirectUri: 'https://dev.plantpaxanalytics.rockwellautomation.com/callback',
    scope: 'ppaxanalytics_api',
    useRefreshToken: true,
    cacheLocation: 'localstorage',
    httpInterceptor: {
      allowedList: [
        `${apiURL}/api/v1/alarm/*`,
        `${apiURL}/api/v1/filter/*`,
        `${apiURL}/api/v1/tenants/*`,
        `${apiURL}/api/v1/device/*`,
      ],
    },
  },
  customAuthConfigForLogin: {
    domain: 'datamosaix-dev.us.auth0.com',
    audience: 'https://cognitedata.com',
    clientId: '9OBZmPELtDuoFWGBOZsmKk7pBxt02byc',
    redirectUri: 'https://dev.plantpaxanalytics.rockwellautomation.com/callback',
    scope: 'IDENTITY',
    connection: 'FTHub-Auth0-DataMosaix',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://cognitedata.com/*'
        },
      ]
    }
  },
  returnToAfterLogout: 'https://dev.plantpaxanalytics.rockwellautomation.com/logout',
};

export const environment = {
  production: false,
  userIdleTimeoutInSec: 600,
  appConfiguration,
  apiURL: `${apiURL}/api/v1`,
  ftHubPortal: `https://home.lemans-sandbox.rockwellautomation.com/entitlement`,
  ftDataMosaixPortal: `https://datamosaix-portal.lemans-sandbox.rockwellautomation.com/applications`,
};
